import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { clearCart, rejectOrder } from "../../../actions";
import { StoreContext } from "../../../context";

const useStyles = makeStyles(() => ({
  rejectBtn: {
    marginRight: "auto",
  },
}));

const schema = z.object({
  reason: z.string().min(3, "Rejection reason is required"),
});

function RejectOrderDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { cartInfo } = ctx;

  const methods = useForm({
    defaultValues: {
      reason: "",
    },
    resolver: zodResolver(schema),
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = methods;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitForm = handleSubmit(
    (data, event) => {
      setOpen(false);
      dispatch(rejectOrder(cartInfo._id, data.reason));
      dispatch(clearCart());
      history.push("/admin");
    },
    (errors) => {
      console.log("Form errors:", errors);
    }
  );

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
        className={classes.rejectBtn}
      >
        Reject Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="reject-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={onSubmitForm}>
          <DialogTitle id="reject-dialog-title">{`Reject order: ${cartInfo?.orderName}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide a rejection reason for the customer.
            </DialogContentText>
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.reason}
                  fullWidth
                  helperText={errors.reason?.message}
                  multiline
                  minRows={3}
                  variant="outlined"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
              className={classes.cancelBtn}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              disabled={!isDirty}
              size="small"
              type="submit"
              variant="contained"
            >
              REJECT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export { RejectOrderDialog };
